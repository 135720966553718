import donalTrump from "./donald.png";
import institution from "./institution.png";
import blueRectangle from "./blueRectangle.png";
export const DonaldTrump = () => {
  return <img src={donalTrump} alt='' />;
};

export const InstitutionLogo = () => {
  return <img src={institution} alt='institution' />;
};

export const BlueRectangleImage = () => {
  return <img src={blueRectangle} alt='institution' />;
};
