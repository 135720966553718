//  Store All SVG
export function LogoIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
    >
      <path
        d='M18.6462 21.1077L18.6462 6.95388L24.8 6.95388L24.8 21.1077L18.6462 21.1077Z'
        fill='#6218C0'
      />
      <path
        d='M19.9385 6.46157C17.9147 6.46157 15.9737 7.25904 14.5427 8.67855C13.1116 10.0981 12.3077 12.0233 12.3077 14.0308C12.3077 16.0383 13.1116 17.9635 14.5427 19.3831C15.9737 20.8026 17.9147 21.6 19.9385 21.6L19.9385 14.0308L19.9385 6.46157Z'
        fill='#6218C0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.8462 14C21.8462 9.66672 18.3333 6.15388 14 6.15388C9.66669 6.15388 6.15385 9.66672 6.15385 14C6.15385 18.3333 9.66669 21.8462 14 21.8462C18.3333 21.8462 21.8462 18.3333 21.8462 14ZM28 14C28 6.26804 21.732 3.05176e-05 14 3.05176e-05C6.26801 3.05176e-05 0 6.26804 0 14C0 21.732 6.26801 28 14 28C21.732 28 28 21.732 28 14Z'
        fill='#6218C0'
      />
    </svg>
  );
}

export function DashboardIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        opacity='0.4'
        d='M21.6702 6.95C21.0302 4.78 19.2202 2.97 17.0502 2.33C15.4002 1.85 14.2602 1.89 13.4702 2.48C12.5202 3.19 12.4102 4.47 12.4102 5.38V7.87C12.4102 10.33 13.5302 11.58 15.7302 11.58H18.6002C19.5002 11.58 20.7902 11.47 21.5002 10.52C22.1102 9.74 22.1602 8.6 21.6702 6.95Z'
        fill='#6218C0'
      />
      <path
        d='M18.9104 13.36C18.6504 13.06 18.2704 12.89 17.8804 12.89H14.3004C12.5404 12.89 11.1104 11.46 11.1104 9.7V6.12C11.1104 5.73 10.9404 5.35 10.6404 5.09C10.3504 4.83 9.95039 4.71 9.57039 4.76C7.22039 5.06 5.06039 6.35 3.65039 8.29C2.23039 10.24 1.71039 12.62 2.16039 15C2.81039 18.44 5.56039 21.19 9.01039 21.84C9.56039 21.95 10.1104 22 10.6604 22C12.4704 22 14.2204 21.44 15.7104 20.35C17.6504 18.94 18.9404 16.78 19.2404 14.43C19.2904 14.04 19.1704 13.65 18.9104 13.36Z'
        fill='#6218C0'
      />
    </svg>
  );
}
export function CategoryIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        opacity='0.6'
        d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.24 3.88 10.9 6.42 10.21C7.11 11.95 8.59 13.29 10.42 13.79C10.92 13.93 11.45 14 12 14C12.55 14 13.08 13.93 13.58 13.79C13.85 14.47 14 15.22 14 16Z'
        fill='#7D8BD6'
      />
      <path
        opacity='0.4'
        d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
        fill='#6218C0'
      />
      <path
        opacity='0.4'
        d='M22 16C22 19.31 19.31 22 16 22C14.46 22 13.06 21.42 12 20.46C13.23 19.37 14 17.77 14 16C14 15.22 13.85 14.47 13.58 13.79C15.41 13.29 16.89 11.95 17.58 10.21C20.12 10.9 22 13.24 22 16Z'
        fill='#7D8BD6'
      />
    </svg>
  );
}

export function UsersIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        opacity='0.4'
        d='M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z'
        fill='#7D8BD6'
      />
      <path
        d='M14.08 14.15C11.29 12.29 6.73996 12.29 3.92996 14.15C2.65996 15 1.95996 16.15 1.95996 17.38C1.95996 18.61 2.65996 19.75 3.91996 20.59C5.31996 21.53 7.15996 22 8.99996 22C10.84 22 12.68 21.53 14.08 20.59C15.34 19.74 16.04 18.6 16.04 17.36C16.03 16.13 15.34 14.99 14.08 14.15Z'
        fill='#7D8BD6'
      />
      <path
        opacity='0.4'
        d='M19.9904 7.34C20.1504 9.28 18.7704 10.98 16.8604 11.21C16.8504 11.21 16.8504 11.21 16.8404 11.21H16.8104C16.7504 11.21 16.6904 11.21 16.6404 11.23C15.6704 11.28 14.7804 10.97 14.1104 10.4C15.1404 9.48 15.7304 8.1 15.6104 6.6C15.5404 5.79 15.2604 5.05 14.8404 4.42C15.2204 4.23 15.6604 4.11 16.1104 4.07C18.0704 3.9 19.8204 5.36 19.9904 7.34Z'
        fill='#7D8BD6'
      />
      <path
        d='M21.9902 16.59C21.9102 17.56 21.2902 18.4 20.2502 18.97C19.2502 19.52 17.9902 19.78 16.7402 19.75C17.4602 19.1 17.8802 18.29 17.9602 17.43C18.0602 16.19 17.4702 15 16.2902 14.05C15.6202 13.52 14.8402 13.1 13.9902 12.79C16.2002 12.15 18.9802 12.58 20.6902 13.96C21.6102 14.7 22.0802 15.63 21.9902 16.59Z'
        fill='#7D8BD6'
      />
    </svg>
  );
}

export function SitesIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        opacity='0.4'
        d='M19.1997 14.78C18.3397 15.57 17.1997 16.01 16.0297 16H6.36973C2.29973 15.71 2.28973 9.80002 6.36973 9.51002H6.40974C3.61974 1.75002 15.4097 -1.34 16.7597 6.8C20.5297 7.28 22.0597 12.29 19.1997 14.78Z'
        fill='#7D8BD6'
      />
      <path
        d='M18.75 21C18.75 21.41 18.41 21.75 18 21.75H14C13.95 21.75 13.91 21.75 13.86 21.73C13.57 22.47 12.84 23 12 23C11.16 23 10.43 22.47 10.14 21.73C10.09 21.75 10.05 21.75 10 21.75H6C5.59 21.75 5.25 21.41 5.25 21C5.25 20.59 5.59 20.25 6 20.25H10C10.05 20.25 10.09 20.25 10.14 20.27C10.34 19.75 10.75 19.34 11.27 19.14C11.25 19.09 11.25 19.05 11.25 19V16H12.75V19C12.75 19.05 12.75 19.09 12.73 19.14C13.25 19.34 13.66 19.75 13.86 20.27C13.91 20.25 13.95 20.25 14 20.25H18C18.41 20.25 18.75 20.59 18.75 21Z'
        fill='#7D8BD6'
      />
    </svg>
  );
}

export function ReportIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        opacity='0.4'
        d='M16.24 3.64999H7.76004C5.29004 3.64999 3.29004 5.65999 3.29004 8.11999V17.53C3.29004 19.99 5.30004 22 7.76004 22H16.23C18.7 22 20.7 19.99 20.7 17.53V8.11999C20.71 5.64999 18.7 3.64999 16.24 3.64999Z'
        fill='#7D8BD6'
      />
      <path
        d='M14.3498 2H9.64977C8.60977 2 7.75977 2.84 7.75977 3.88V4.82C7.75977 5.86 8.59977 6.7 9.63977 6.7H14.3498C15.3898 6.7 16.2298 5.86 16.2298 4.82V3.88C16.2398 2.84 15.3898 2 14.3498 2Z'
        fill='#7D8BD6'
      />
      <path
        d='M15 12.95H8C7.59 12.95 7.25 12.61 7.25 12.2C7.25 11.79 7.59 11.45 8 11.45H15C15.41 11.45 15.75 11.79 15.75 12.2C15.75 12.61 15.41 12.95 15 12.95Z'
        fill='#7D8BD6'
      />
      <path
        d='M12.38 16.95H8C7.59 16.95 7.25 16.61 7.25 16.2C7.25 15.79 7.59 15.45 8 15.45H12.38C12.79 15.45 13.13 15.79 13.13 16.2C13.13 16.61 12.79 16.95 12.38 16.95Z'
        fill='#7D8BD6'
      />
    </svg>
  );
}

export function NotificationsIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        opacity='0.4'
        d='M20.4002 16.33C20.1202 17.08 19.5302 17.65 18.7602 17.91C17.6802 18.27 16.5702 18.54 15.4502 18.73C15.3402 18.75 15.2302 18.77 15.1202 18.78C14.9402 18.81 14.7602 18.83 14.5802 18.85C14.3602 18.88 14.1302 18.9 13.9002 18.92C13.2702 18.97 12.6502 19 12.0202 19C11.3802 19 10.7402 18.97 10.1102 18.91C9.8402 18.89 9.5802 18.86 9.3202 18.82C9.1702 18.8 9.0202 18.78 8.8802 18.76C8.7702 18.74 8.6602 18.73 8.5502 18.71C7.4402 18.53 6.3402 18.26 5.2702 17.9C4.4702 17.63 3.8602 17.06 3.5902 16.33C3.3202 15.61 3.4202 14.77 3.8502 14.05L4.9802 12.17C5.2202 11.76 5.4402 10.97 5.4402 10.49V8.63C5.4402 5 8.3902 2.05 12.0202 2.05C15.6402 2.05 18.5902 5 18.5902 8.63V10.49C18.5902 10.97 18.8102 11.76 19.0602 12.17L20.1902 14.05C20.6002 14.75 20.6802 15.57 20.4002 16.33Z'
        fill='#7D8BD6'
      />
      <path
        d='M12.0002 10.76C11.5802 10.76 11.2402 10.42 11.2402 10V6.9C11.2402 6.48 11.5802 6.14 12.0002 6.14C12.4202 6.14 12.7602 6.48 12.7602 6.9V10C12.7502 10.42 12.4102 10.76 12.0002 10.76Z'
        fill='#7D8BD6'
      />
      <path
        d='M14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01Z'
        fill='#7D8BD6'
      />
    </svg>
  );
}

export function InstitutiionIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M22 19V22H2V19C2 18.45 2.45 18 3 18H21C21.55 18 22 18.45 22 19Z'
        fill='#7D8BD6'
        stroke='#7D8BD6'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path opacity='0.6' d='M8 11H4V18H8V11Z' fill='#7D8BD6' />
      <path opacity='0.4' d='M12 11H8V18H12V11Z' fill='#7D8BD6' />
      <path opacity='0.6' d='M16 11H12V18H16V11Z' fill='#7D8BD6' />
      <path opacity='0.4' d='M20 11H16V18H20V11Z' fill='#7D8BD6' />
      <path
        opacity='0.4'
        d='M23 22.75H1C0.59 22.75 0.25 22.41 0.25 22C0.25 21.59 0.59 21.25 1 21.25H23C23.41 21.25 23.75 21.59 23.75 22C23.75 22.41 23.41 22.75 23 22.75Z'
        fill='#7D8BD6'
      />
      <path
        d='M21.37 5.75L12.37 2.15003C12.17 2.07003 11.83 2.07003 11.63 2.15003L2.63 5.75C2.28 5.89 2 6.29999 2 6.68V10C2 10.55 2.45 11 3 11H21C21.55 11 22 10.55 22 10V6.68C22 6.29999 21.72 5.89 21.37 5.75ZM12 8.5C11.17 8.5 10.5 7.83 10.5 7C10.5 6.17 11.17 5.5 12 5.5C12.83 5.5 13.5 6.17 13.5 7C13.5 7.83 12.83 8.5 12 8.5Z'
        fill='#7D8BD6'
      />
    </svg>
  );
}

export function AddSiteIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
    >
      <path
        d='M11.0002 1.83333C8.5985 1.83333 6.646 3.78583 6.646 6.1875C6.646 8.54333 8.4885 10.45 10.8902 10.5325C10.9635 10.5233 11.0368 10.5233 11.0918 10.5325C11.1102 10.5325 11.1193 10.5325 11.1377 10.5325C11.1468 10.5325 11.1468 10.5325 11.156 10.5325C13.5027 10.45 15.3452 8.54333 15.3543 6.1875C15.3543 3.78583 13.4018 1.83333 11.0002 1.83333Z'
        fill='#6218C0'
      />
      <path
        d='M15.6569 12.9708C13.0994 11.2658 8.92854 11.2658 6.35271 12.9708C5.18854 13.75 4.54688 14.8042 4.54688 15.9317C4.54688 17.0592 5.18854 18.1042 6.34354 18.8742C7.62688 19.7358 9.31354 20.1667 11.0002 20.1667C12.6869 20.1667 14.3735 19.7358 15.6569 18.8742C16.8119 18.095 17.4535 17.05 17.4535 15.9133C17.4444 14.7858 16.8119 13.7408 15.6569 12.9708ZM12.8335 16.6192H11.6877V17.765C11.6877 18.1408 11.376 18.4525 11.0002 18.4525C10.6244 18.4525 10.3127 18.1408 10.3127 17.765V16.6192H9.16688C8.79104 16.6192 8.47938 16.3075 8.47938 15.9317C8.47938 15.5558 8.79104 15.2442 9.16688 15.2442H10.3127V14.0983C10.3127 13.7225 10.6244 13.4108 11.0002 13.4108C11.376 13.4108 11.6877 13.7225 11.6877 14.0983V15.2442H12.8335C13.2094 15.2442 13.521 15.5558 13.521 15.9317C13.521 16.3075 13.2094 16.6192 12.8335 16.6192Z'
        fill='#6218C0'
      />
    </svg>
  );
}

export function SearchIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z'
        stroke='#667085'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export function SortIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M2.5 5.83334H17.5'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M5 10H15'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M8.3335 14.1667H11.6668'
        stroke='black'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  );
}

export function ChevlonIcon(param) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill={param}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.23017 7.20938C5.52875 6.92228 6.00353 6.93159 6.29063 7.23017L10 11.1679L13.7094 7.23017C13.9965 6.93159 14.4713 6.92228 14.7698 7.20938C15.0684 7.49647 15.0777 7.97125 14.7906 8.26983L10.5406 12.7698C10.3992 12.9169 10.204 13 10 13C9.79599 13 9.60078 12.9169 9.45938 12.7698L5.20938 8.26983C4.92228 7.97125 4.93159 7.49647 5.23017 7.20938Z'
        fill={param}
      />
    </svg>
  );
}

export function FilterIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
    >
      <path
        d='M4.875 16.5V11.25'
        stroke='black'
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.875 3.75V1.5'
        stroke='black'
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.125 16.5V14.25'
        stroke='black'
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.125 6.75V1.5'
        stroke='black'
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.125 5.25V9.75C7.125 10.575 6.75 11.25 5.625 11.25H4.125C3 11.25 2.625 10.575 2.625 9.75V5.25C2.625 4.425 3 3.75 4.125 3.75H5.625C6.75 3.75 7.125 4.425 7.125 5.25Z'
        stroke='black'
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.375 8.25V12.75C15.375 13.575 15 14.25 13.875 14.25H12.375C11.25 14.25 10.875 13.575 10.875 12.75V8.25C10.875 7.425 11.25 6.75 12.375 6.75H13.875C15 6.75 15.375 7.425 15.375 8.25Z'
        stroke='black'
        strokeWidth='1.2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export function EditICon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
    >
      <path
        d='M10.084 1.83331H8.25065C3.66732 1.83331 1.83398 3.66665 1.83398 8.24998V13.75C1.83398 18.3333 3.66732 20.1666 8.25065 20.1666H13.7507C18.334 20.1666 20.1673 18.3333 20.1673 13.75V11.9166'
        stroke='#2B3674'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.7027 2.76832L7.4794 9.99165C7.2044 10.2667 6.9294 10.8075 6.8744 11.2017L6.48023 13.9608C6.33357 14.96 7.0394 15.6567 8.03857 15.5192L10.7977 15.125C11.1827 15.07 11.7236 14.795 12.0077 14.52L19.2311 7.29665C20.4777 6.04999 21.0644 4.60165 19.2311 2.76832C17.3977 0.934987 15.9494 1.52165 14.7027 2.76832Z'
        stroke='#2B3674'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.668 3.80414C14.2821 5.99497 15.9963 7.70914 18.1963 8.33247'
        stroke='#2B3674'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export function DeleteIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
    >
      <path
        d='M21.875 6.22916C18.4062 5.88541 14.9167 5.70833 11.4375 5.70833C9.375 5.70833 7.3125 5.8125 5.25 6.02083L3.125 6.22916'
        stroke='#F82525'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.854 5.17708L9.08317 3.81249C9.24984 2.82291 9.37484 2.08333 11.1353 2.08333H13.8644C15.6248 2.08333 15.7603 2.86458 15.9165 3.82291L16.1457 5.17708'
        stroke='#F82525'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.6356 9.52083L18.9585 20.0104C18.8439 21.6458 18.7502 22.9167 15.8439 22.9167H9.15641C6.25016 22.9167 6.15641 21.6458 6.04183 20.0104L5.36475 9.52083'
        stroke='#F82525'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.7603 17.1875H14.229'
        stroke='#F82525'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.896 13.0208H15.1043'
        stroke='#F82525'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export function FileIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M18.2753 5.63336L15.4669 16.9084C15.2669 17.7501 14.517 18.3334 13.6503 18.3334H2.70027C1.44194 18.3334 0.541951 17.1 0.916951 15.8916L4.42528 4.62506C4.66694 3.84173 5.39196 3.29999 6.20862 3.29999H16.4586C17.2503 3.29999 17.9086 3.78333 18.1836 4.44999C18.342 4.80833 18.3753 5.2167 18.2753 5.63336Z'
        stroke='#2B3674'
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
      <path
        d='M13.333 18.3333H17.3163C18.3913 18.3333 19.233 17.425 19.158 16.35L18.333 5'
        stroke='#2B3674'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.06641 5.31669L8.93308 1.71674'
        stroke='#2B3674'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.6504 5.32501L14.4337 1.70837'
        stroke='#2B3674'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.41699 10H13.0837'
        stroke='#2B3674'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.58301 13.3334H12.2497'
        stroke='#2B3674'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export function ThreeDots() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M10 19C10 20.1 10.9 21 12 21C13.1 21 14 20.1 14 19C14 17.9 13.1 17 12 17C10.9 17 10 17.9 10 19Z'
        stroke='#6218C0'
        strokeWidth='1.5'
      />
      <path
        d='M10 5C10 6.1 10.9 7 12 7C13.1 7 14 6.1 14 5C14 3.9 13.1 3 12 3C10.9 3 10 3.9 10 5Z'
        stroke='#6218C0'
        strokeWidth='1.5'
      />
      <path
        d='M10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10C10.9 10 10 10.9 10 12Z'
        stroke='#6218C0'
        strokeWidth='1.5'
      />
    </svg>
  );
}

export function BulkUserIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        opacity='0.4'
        d='M17.53 7.77C17.46 7.76 17.39 7.76 17.32 7.77C15.77 7.72 14.54 6.45 14.54 4.89C14.54 3.3 15.83 2 17.43 2C19.02 2 20.32 3.29 20.32 4.89C20.31 6.45 19.08 7.72 17.53 7.77Z'
        fill='#6218C0'
      />
      <path
        opacity='0.4'
        d='M20.7901 14.7C19.6701 15.45 18.1001 15.73 16.6501 15.54C17.0301 14.72 17.2301 13.81 17.2401 12.85C17.2401 11.85 17.0201 10.9 16.6001 10.07C18.0801 9.87004 19.6501 10.15 20.7801 10.9C22.3601 11.94 22.3601 13.65 20.7901 14.7Z'
        fill='#6218C0'
      />
      <path
        opacity='0.4'
        d='M6.4399 7.77C6.5099 7.76 6.5799 7.76 6.6499 7.77C8.1999 7.72 9.4299 6.45 9.4299 4.89C9.4299 3.3 8.1399 2 6.5399 2C4.9499 2 3.6499 3.29 3.6499 4.89C3.6599 6.45 4.8899 7.72 6.4399 7.77Z'
        fill='#6218C0'
      />
      <path
        opacity='0.4'
        d='M6.55012 12.85C6.55012 13.82 6.76012 14.74 7.14012 15.57C5.73012 15.72 4.26012 15.42 3.18012 14.71C1.60012 13.66 1.60012 11.95 3.18012 10.9C4.25012 10.18 5.76012 9.88998 7.18012 10.05C6.77012 10.89 6.55012 11.84 6.55012 12.85Z'
        fill='#6218C0'
      />
      <path
        d='M12.1198 15.87C12.0398 15.86 11.9498 15.86 11.8598 15.87C10.0198 15.81 8.5498 14.3 8.5498 12.44C8.5498 10.54 10.0798 9 11.9898 9C13.8898 9 15.4298 10.54 15.4298 12.44C15.4298 14.3 13.9698 15.81 12.1198 15.87Z'
        fill='#6218C0'
      />
      <path
        d='M8.8698 17.94C7.3598 18.95 7.3598 20.61 8.8698 21.61C10.5898 22.76 13.4098 22.76 15.1298 21.61C16.6398 20.6 16.6398 18.94 15.1298 17.94C13.4198 16.79 10.5998 16.79 8.8698 17.94Z'
        fill='#6218C0'
      />
    </svg>
  );
}

export function ThickIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='12'
      viewBox='0 0 16 12'
      fill='none'
    >
      <path
        d='M5.52773 11.9186C5.2187 11.9186 4.92513 11.7855 4.70881 11.5527L0.336067 6.84683C-0.112022 6.3646 -0.112022 5.56643 0.336067 5.0842C0.784157 4.60197 1.52582 4.60197 1.97391 5.0842L5.52773 8.90878L13.4697 0.361672C13.9178 -0.120557 14.6595 -0.120557 15.1076 0.361672C15.5557 0.843902 15.5557 1.64208 15.1076 2.12431L6.34665 11.5527C6.13033 11.7855 5.83676 11.9186 5.52773 11.9186Z'
        fill='white'
      />
    </svg>
  );
}

export function DownloadIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='51'
      height='51'
      viewBox='0 0 51 51'
      fill='none'
    >
      <g clip-path='url(#clip0_2_12511)'>
        <path
          d='M21.25 34H29.75C30.9188 34 31.875 33.0437 31.875 31.875V21.25H35.2537C37.145 21.25 38.1012 18.955 36.7625 17.6162L27.0088 7.8625C26.18 7.03375 24.8413 7.03375 24.0125 7.8625L14.2587 17.6162C12.92 18.955 13.855 21.25 15.7462 21.25H19.125V31.875C19.125 33.0437 20.0813 34 21.25 34ZM12.75 38.25H38.25C39.4188 38.25 40.375 39.2062 40.375 40.375C40.375 41.5437 39.4188 42.5 38.25 42.5H12.75C11.5812 42.5 10.625 41.5437 10.625 40.375C10.625 39.2062 11.5812 38.25 12.75 38.25Z'
          fill='#6822C3'
        />
      </g>
      <defs>
        <clipPath id='clip0_2_12511'>
          <rect width='51' height='51' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export function CrossIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <g clip-path='url(#clip0_2_12496)'>
        <path
          d='M9.99865 8.82227L14.1236 4.69727L15.302 5.8756L11.177 10.0006L15.302 14.1256L14.1236 15.3039L9.99865 11.1789L5.87365 15.3039L4.69531 14.1256L8.82031 10.0006L4.69531 5.8756L5.87365 4.69727L9.99865 8.82227Z'
          fill='#656777'
        />
      </g>
      <defs>
        <clipPath id='clip0_2_12496'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export function CloudDownloadIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
    >
      <g clip-path='url(#clip0_2_12519)'>
        <path
          d='M5.99989 12.75L8.99989 15.75M8.99989 15.75L11.9999 12.75M8.99989 15.75V9M15.6599 13.5675C16.3119 13.109 16.8009 12.4546 17.0559 11.6994C17.3108 10.9441 17.3184 10.1273 17.0777 9.36737C16.8369 8.60747 16.3603 7.94407 15.7169 7.47342C15.0736 7.00277 14.297 6.74938 13.4999 6.75H12.5549C12.3293 5.8709 11.9073 5.05444 11.3205 4.36206C10.7337 3.66968 9.9975 3.11944 9.1673 2.75277C8.33709 2.38609 7.43452 2.21252 6.52753 2.24514C5.62055 2.27775 4.73277 2.5157 3.93105 2.94107C3.12933 3.36644 2.43456 3.96814 1.89903 4.70087C1.3635 5.43361 1.00118 6.27828 0.839334 7.17131C0.677491 8.06434 0.720346 8.98245 0.964674 9.85652C1.209 10.7306 1.64843 11.5378 2.24989 12.2175'
          stroke='#103E7E'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2_12519'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export function AvatarIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14'
      height='18'
      viewBox='0 0 14 18'
      fill='none'
    >
      <path
        d='M7.08932 8.16406C7.00855 8.15598 6.91163 8.15598 6.82279 8.16406C4.90053 8.09944 3.37402 6.52448 3.37402 4.58607C3.37402 2.60727 4.97322 1 6.96009 1C8.93889 1 10.5462 2.60727 10.5462 4.58607C10.5381 6.52448 9.01158 8.09944 7.08932 8.16406Z'
        stroke='#103E7E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.05089 11.1444C1.09632 12.4528 1.09632 14.585 3.05089 15.8854C5.27199 17.3715 8.91459 17.3715 11.1357 15.8854C13.0903 14.577 13.0903 12.4447 11.1357 11.1444C8.92267 9.66632 5.28006 9.66632 3.05089 11.1444Z'
        stroke='#103E7E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
export function LinkIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M15.0019 11.4789L15.8917 10.5891C17.6712 8.80955 17.6771 5.89274 15.8917 4.10729C14.1121 2.32774 11.1953 2.32185 9.40986 4.10729L8.52008 4.99707'
        stroke='#103E7E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.99085 8.52707L4.10696 9.41095C2.32152 11.1964 2.32152 14.1073 4.10696 15.8928C5.88652 17.6723 8.80333 17.6782 10.5888 15.8928L11.4727 15.0089'
        stroke='#103E7E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.64221 12.3571L12.3563 7.64307'
        stroke='#103E7E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export function ExportIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
    >
      <path
        d='M6.75 8.24995V12.75L8.25 11.25'
        stroke='#6218C0'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.74805 12.75L5.24805 11.25'
        stroke='#6218C0'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.502 7.49994V11.2499C16.502 14.9999 15.002 16.4999 11.252 16.4999H6.75195C3.00195 16.4999 1.50195 14.9999 1.50195 11.2499V6.74994C1.50195 2.99994 3.00195 1.49994 6.75195 1.49994H10.502'
        stroke='#6218C0'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.502 7.49994H13.502C11.252 7.49994 10.502 6.74994 10.502 4.49994V1.49994L16.502 7.49994Z'
        stroke='#6218C0'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export function CalenderIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M6.6665 1.66667V4.16667'
        stroke='#292D32'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.3335 1.66667V4.16667'
        stroke='#292D32'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.9165 7.575H17.0832'
        stroke='#292D32'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.5 7.08333V14.1667C17.5 16.6667 16.25 18.3333 13.3333 18.3333H6.66667C3.75 18.3333 2.5 16.6667 2.5 14.1667V7.08333C2.5 4.58333 3.75 2.91667 6.66667 2.91667H13.3333C16.25 2.91667 17.5 4.58333 17.5 7.08333Z'
        stroke='#292D32'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.99607 11.4167H10.0036'
        stroke='#292D32'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.91209 11.4167H6.91957'
        stroke='#292D32'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.91209 13.9167H6.91957'
        stroke='#292D32'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export const EmailIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
    >
      <path
        d='M15.4766 18.4376H6.72656C4.10156 18.4376 2.35156 17.1251 2.35156 14.0626V7.93756C2.35156 4.87506 4.10156 3.56256 6.72656 3.56256H15.4766C18.1016 3.56256 19.8516 4.87506 19.8516 7.93756V14.0626C19.8516 17.1251 18.1016 18.4376 15.4766 18.4376Z'
        stroke='#103E7E'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.4766 8.375L12.7378 10.5625C11.8366 11.28 10.3578 11.28 9.45656 10.5625L6.72656 8.375'
        stroke='#103E7E'
        strokeWidth='1.5'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const PhoneIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
    >
      <path
        d='M17.5776 14.064C17.5776 14.352 17.5136 14.648 17.3776 14.936C17.2416 15.224 17.0656 15.496 16.8336 15.752C16.4416 16.184 16.0096 16.496 15.5216 16.696C15.0416 16.896 14.5216 17 13.9616 17C13.1456 17 12.2736 16.808 11.3536 16.416C10.4336 16.024 9.51356 15.496 8.60156 14.832C7.68156 14.16 6.80956 13.416 5.97756 12.592C5.15356 11.76 4.40956 10.888 3.74556 9.976C3.08956 9.064 2.56156 8.152 2.17756 7.248C1.79356 6.336 1.60156 5.464 1.60156 4.632C1.60156 4.088 1.69756 3.568 1.88956 3.088C2.08156 2.6 2.38556 2.152 2.80956 1.752C3.32156 1.248 3.88156 1 4.47356 1C4.69756 1 4.92156 1.048 5.12156 1.144C5.32956 1.24 5.51356 1.384 5.65756 1.592L7.51356 4.208C7.65756 4.408 7.76156 4.592 7.83356 4.768C7.90556 4.936 7.94556 5.104 7.94556 5.256C7.94556 5.448 7.88956 5.64 7.77756 5.824C7.67356 6.008 7.52156 6.2 7.32956 6.392L6.72156 7.024C6.63356 7.112 6.59356 7.216 6.59356 7.344C6.59356 7.408 6.60156 7.464 6.61756 7.528C6.64156 7.592 6.66556 7.64 6.68156 7.688C6.82556 7.952 7.07356 8.296 7.42556 8.712C7.78556 9.128 8.16956 9.552 8.58556 9.976C9.01756 10.4 9.43356 10.792 9.85756 11.152C10.2736 11.504 10.6176 11.744 10.8896 11.888C10.9296 11.904 10.9776 11.928 11.0336 11.952C11.0976 11.976 11.1616 11.984 11.2336 11.984C11.3696 11.984 11.4736 11.936 11.5616 11.848L12.1696 11.248C12.3696 11.048 12.5616 10.896 12.7456 10.8C12.9296 10.688 13.1136 10.632 13.3136 10.632C13.4656 10.632 13.6256 10.664 13.8016 10.736C13.9776 10.808 14.1616 10.912 14.3616 11.048L17.0096 12.928C17.2176 13.072 17.3616 13.24 17.4496 13.44C17.5296 13.64 17.5776 13.84 17.5776 14.064Z'
        stroke='#103E7E'
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
    </svg>
  );
};

export const LocationIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='18'
      viewBox='0 0 16 18'
      fill='none'
    >
      <path
        d='M7.89994 10.1463C9.27879 10.1463 10.3966 9.02849 10.3966 7.64964C10.3966 6.27079 9.27879 5.15302 7.89994 5.15302C6.5211 5.15302 5.40332 6.27079 5.40332 7.64964C5.40332 9.02849 6.5211 10.1463 7.89994 10.1463Z'
        stroke='#103E7E'
        strokeWidth='1.5'
      />
      <path
        d='M1.19425 6.1933C2.77065 -0.736432 13.0372 -0.72843 14.6056 6.2013C15.5258 10.2663 12.9972 13.7072 10.7806 15.8357C9.17225 17.3881 6.62761 17.3881 5.01121 15.8357C2.80266 13.7072 0.274023 10.2583 1.19425 6.1933Z'
        stroke='#103E7E'
        strokeWidth='1.5'
      />
    </svg>
  );
};

export const LoginIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
    >
      <path
        opacity='0.13'
        d='M15.4378 5.66521V5.66678V12.3334C15.4378 13.3391 15.1257 14.1051 14.612 14.6188C14.0984 15.1324 13.3324 15.4446 12.3267 15.4446H10.5281C9.52245 15.4446 8.75657 15.1324 8.24301 14.6182C7.72939 14.1038 7.41699 13.336 7.41699 12.3265V5.66678C7.41699 4.66111 7.72914 3.89507 8.24277 3.38144C8.7564 2.86781 9.52244 2.55566 10.5281 2.55566H12.3337C13.3394 2.55566 14.1053 2.86786 14.6179 3.38119C15.1304 3.89442 15.441 4.65994 15.4378 5.66521Z'
        stroke='#F82525'
      />
      <path
        d='M4.53505 6.30566L2.20866 8.63205C2.00727 8.83344 2.00727 9.16678 2.20866 9.36816L4.53505 11.6946C4.73644 11.8959 5.06977 11.8959 5.27116 11.6946C5.47255 11.4932 5.47255 11.1598 5.27116 10.9584L3.83366 9.52094H11.2573C11.542 9.52094 11.7781 9.28483 11.7781 9.00011C11.7781 8.71539 11.542 8.47928 11.2573 8.47928H3.83366L5.27116 7.04178C5.37533 6.93761 5.42394 6.80566 5.42394 6.67372C5.42394 6.54178 5.37533 6.40289 5.27116 6.30566C5.06977 6.09733 4.74338 6.09733 4.53505 6.30566Z'
        fill='#F82525'
      />
    </svg>
  );
};

export const EducationIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='18'
      viewBox='0 0 17 18'
      fill='none'
    >
      <path
        d='M6.92628 1.42508L2.10939 4.56967C0.5651 5.57785 0.5651 7.83427 2.10939 8.84246L6.92628 11.987C7.79044 12.5551 9.2147 12.5551 10.0789 11.987L14.8717 8.84246C16.408 7.83427 16.408 5.58585 14.8717 4.57767L10.0789 1.43309C9.2147 0.856979 7.79044 0.856979 6.92628 1.42508Z'
        stroke='#103E7E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.38984 9.86668L3.38184 13.6194C3.38184 14.6356 4.16598 15.7238 5.12616 16.0438L7.67863 16.892C8.11871 17.036 8.84685 17.036 9.29493 16.892L11.8474 16.0438C12.8076 15.7238 13.5917 14.6356 13.5917 13.6194V9.90668'
        stroke='#103E7E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.0078 11.4029V6.60206'
        stroke='#103E7E'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const UpGreenArrow = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
    >
      <path
        d='M4.35716 2.3925L0.908974 5.745L5.0443e-07 4.86125L5 -5.1656e-07L10 4.86125L9.09103 5.745L5.64284 2.3925L5.64284 10L4.35716 10L4.35716 2.3925Z'
        fill='#22AD5C'
      />
    </svg>
  );
};
export const DownRedArrow = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
    >
      <path
        d='M4.35716 2.3925L0.908974 5.745L5.0443e-07 4.86125L5 -5.1656e-07L10 4.86125L9.09103 5.745L5.64284 2.3925L5.64284 10L4.35716 10L4.35716 2.3925Z'
        fill='red'
      />
    </svg>
  );
};

export const UserEmptyIcon = ({ fill = "#3758F9" }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
    >
      <rect opacity='0.08' width='50' height='50' rx='3' fill='#3758F9' />
      <path
        d='M21.9189 24.5883C21.8864 24.5883 21.8647 24.5883 21.8322 24.5883C21.778 24.5775 21.7022 24.5775 21.6372 24.5883C18.4955 24.4908 16.123 22.0208 16.123 18.9766C16.123 15.8783 18.6472 13.3541 21.7455 13.3541C24.8439 13.3541 27.368 15.8783 27.368 18.9766C27.3572 22.0208 24.9739 24.4908 21.9514 24.5883C21.9405 24.5883 21.9297 24.5883 21.9189 24.5883ZM21.7455 14.9791C19.5464 14.9791 17.748 16.7775 17.748 18.9766C17.748 21.1433 19.438 22.8875 21.5939 22.9633C21.6589 22.9525 21.7997 22.9525 21.9405 22.9633C24.0639 22.8658 25.7322 21.1216 25.743 18.9766C25.743 16.7775 23.9447 14.9791 21.7455 14.9791Z'
        fill={fill}
      />
      <path
        d='M29.9137 24.7292C29.8812 24.7292 29.8487 24.7292 29.8162 24.7184C29.372 24.7617 28.917 24.4475 28.8737 24.0034C28.8303 23.5592 29.1012 23.1584 29.5453 23.1042C29.6753 23.0934 29.8162 23.0934 29.9353 23.0934C31.517 23.0067 32.752 21.7067 32.752 20.1142C32.752 18.4675 31.4195 17.135 29.7728 17.135C29.3287 17.1459 28.9603 16.7775 28.9603 16.3334C28.9603 15.8892 29.3287 15.5209 29.7728 15.5209C32.3078 15.5209 34.377 17.59 34.377 20.125C34.377 22.6167 32.427 24.6317 29.9462 24.7292C29.9353 24.7292 29.9245 24.7292 29.9137 24.7292Z'
        fill={fill}
      />
      <path
        d='M21.9333 36.4292C19.8099 36.4292 17.6758 35.8875 16.0616 34.8042C14.5558 33.8075 13.7324 32.4425 13.7324 30.9584C13.7324 29.4742 14.5558 28.0984 16.0616 27.0909C19.3116 24.935 24.5766 24.935 27.8049 27.0909C29.2999 28.0875 30.1341 29.4525 30.1341 30.9367C30.1341 32.4209 29.3108 33.7967 27.8049 34.8042C26.1799 35.8875 24.0566 36.4292 21.9333 36.4292ZM16.9608 28.4559C15.9208 29.1492 15.3574 30.0375 15.3574 30.9692C15.3574 31.89 15.9316 32.7784 16.9608 33.4609C19.6583 35.27 24.2083 35.27 26.9058 33.4609C27.9458 32.7675 28.5091 31.8792 28.5091 30.9475C28.5091 30.0267 27.9349 29.1384 26.9058 28.4559C24.2083 26.6575 19.6583 26.6575 16.9608 28.4559Z'
        fill={fill}
      />
      <path
        d='M31.868 34.4792C31.4889 34.4792 31.153 34.2192 31.0772 33.8292C30.9905 33.385 31.2722 32.9625 31.7055 32.865C32.388 32.7242 33.0164 32.4534 33.5039 32.0742C34.1214 31.6084 34.4572 31.0234 34.4572 30.4059C34.4572 29.7884 34.1214 29.2034 33.5147 28.7484C33.038 28.38 32.4422 28.12 31.738 27.9575C31.3047 27.86 31.023 27.4267 31.1205 26.9825C31.218 26.5492 31.6514 26.2675 32.0955 26.365C33.0272 26.5709 33.8397 26.9392 34.5005 27.4484C35.508 28.2067 36.0822 29.2792 36.0822 30.4059C36.0822 31.5325 35.4972 32.605 34.4897 33.3742C33.818 33.8942 32.973 34.2734 32.0414 34.4575C31.9764 34.4792 31.9222 34.4792 31.868 34.4792Z'
        fill={fill}
      />
    </svg>
  );
};

export const ResourceIcon = ({ fill = "#FA7C0B" }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='22'
      viewBox='0 0 24 22'
      fill='none'
    >
      <path
        d='M18.727 4.43768L13.0616 2.0691C12.2302 1.72425 10.9734 1.72425 10.1419 2.0691L4.47654 4.43768C3.0457 5.03663 2.83301 5.85338 2.83301 6.28898C2.83301 6.72458 3.0457 7.54133 4.47654 8.14028L10.1419 10.5089C10.5576 10.6813 11.0797 10.772 11.6018 10.772C12.1238 10.772 12.6459 10.6813 13.0616 10.5089L18.727 8.14028C20.1578 7.54133 20.3705 6.72458 20.3705 6.28898C20.3705 5.85338 20.1675 5.03663 18.727 4.43768Z'
        fill={fill}
      />
      <path
        opacity='0.4'
        d='M11.6017 15.4638C11.2343 15.4638 10.8669 15.3912 10.5285 15.2551L4.01238 12.5326C3.01659 12.1151 2.24316 10.9989 2.24316 9.97343C2.24316 9.60135 2.5622 9.30188 2.95859 9.30188C3.35497 9.30188 3.67401 9.60135 3.67401 9.97343C3.67401 10.4635 4.10906 11.0987 4.59246 11.2984L11.1086 14.0209C11.418 14.1479 11.7757 14.1479 12.0851 14.0209L18.6012 11.2984C19.0846 11.0987 19.5196 10.4726 19.5196 9.97343C19.5196 9.60135 19.8387 9.30188 20.2351 9.30188C20.6314 9.30188 20.9505 9.60135 20.9505 9.97343C20.9505 10.9898 20.1771 12.1151 19.1813 12.5326L12.6651 15.2551C12.3364 15.3912 11.969 15.4638 11.6017 15.4638Z'
        fill={fill}
      />
      <path
        opacity='0.4'
        d='M11.6017 19.965C11.2343 19.965 10.8669 19.8924 10.5285 19.7563L4.01238 17.0338C2.93925 16.5891 2.24316 15.5818 2.24316 14.4746C2.24316 14.1026 2.5622 13.8031 2.95859 13.8031C3.35497 13.8031 3.67401 14.1026 3.67401 14.4746C3.67401 15.0464 4.03172 15.5636 4.59246 15.7996L11.1086 18.5221C11.418 18.6491 11.7757 18.6491 12.0851 18.5221L18.6012 15.7996C19.1523 15.5727 19.5196 15.0464 19.5196 14.4746C19.5196 14.1026 19.8387 13.8031 20.2351 13.8031C20.6314 13.8031 20.9505 14.1026 20.9505 14.4746C20.9505 15.5818 20.2544 16.58 19.1813 17.0338L12.6651 19.7563C12.3364 19.8924 11.969 19.965 11.6017 19.965Z'
        fill={fill}
      />
    </svg>
  );
};

export const NewlyResourceAddedIcon = ({ fill = "#F2994A" }) => {
  return (
    <svg
      width='23'
      height='23'
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='vuesax/outline/clipboard'>
        <g id='clipboard'>
          <path
            id='Vector'
            d='M12.5061 18.6108C11.9886 18.6108 11.4423 18.5629 10.8482 18.4671L6.3536 17.7579C4.93526 17.5375 3.84276 17.1446 3.02818 16.5696C0.929429 15.1129 1.05401 12.6117 1.32235 10.8579L2.03151 6.36333C2.68318 2.2425 4.81068 0.699584 8.93151 1.34167L13.4261 2.05083C15.544 2.38625 18.3903 3.23917 18.5723 6.81375C18.6107 7.42708 18.5723 8.12667 18.4382 8.95083L17.7386 13.4454C17.1828 16.9625 15.544 18.6108 12.5061 18.6108ZM7.2161 2.63542C4.93526 2.63542 3.90026 3.795 3.44985 6.59333L2.74068 11.0879C2.29985 13.915 3.10485 14.8733 3.85235 15.4004C4.48485 15.8508 5.3761 16.1575 6.57401 16.3396L11.0686 17.0488C14.4132 17.5758 15.7932 16.5696 16.3203 13.2154L17.0198 8.72083C17.1348 8.00208 17.1732 7.39833 17.1348 6.89042V6.88083C17.0294 4.86833 15.889 3.88125 13.1961 3.45958L8.7111 2.76C8.15526 2.67375 7.66651 2.63542 7.2161 2.63542Z'
            fill={fill}
          />
          <path
            id='Vector_2'
            d='M14.0682 21.8021C13.2536 21.8021 12.3336 21.6296 11.2699 21.275L6.94779 19.8375C4.48488 19.0229 3.15279 17.8538 2.75029 16.1479C2.68321 15.8604 2.79821 15.5537 3.04738 15.3908C3.29654 15.2279 3.62238 15.2279 3.86196 15.4004C4.49446 15.8508 5.37613 16.1575 6.57404 16.3396L11.0686 17.0488C14.4132 17.5758 15.7932 16.5696 16.3203 13.2154L17.0199 8.72083C17.1349 8.00208 17.1732 7.39833 17.1349 6.89042C17.1253 6.64125 17.2499 6.39208 17.4703 6.24833C17.6907 6.10458 17.9686 6.095 18.1986 6.21958C20.767 7.59 21.4474 9.78458 20.3357 13.1388L18.8982 17.4608C18.2178 19.4925 17.3265 20.7383 16.0807 21.3613C15.477 21.6583 14.8157 21.8021 14.0682 21.8021ZM5.49113 17.595C5.95113 17.9017 6.56446 18.1988 7.39821 18.4767L11.7203 19.9142C13.3686 20.4604 14.5474 20.5083 15.429 20.0771C16.3107 19.6363 16.9815 18.6588 17.5278 17.0104L18.9653 12.6883C19.7511 10.3117 19.4445 9.04667 18.534 8.19375C18.5149 8.43333 18.4765 8.6825 18.4382 8.95083L17.7386 13.4454C17.087 17.5663 14.9595 19.1092 10.8386 18.4767L6.34404 17.7675C6.04696 17.71 5.75946 17.6525 5.49113 17.595Z'
            fill={fill}
          />
          <path
            id='Vector_3'
            d='M7.89699 9.315C6.58408 9.315 5.51074 8.24166 5.51074 6.92875C5.51074 5.61583 6.58408 4.5425 7.89699 4.5425C9.20991 4.5425 10.2832 5.61583 10.2832 6.92875C10.2832 8.24166 9.20991 9.315 7.89699 9.315ZM7.89699 5.98958C7.37949 5.98958 6.94824 6.41125 6.94824 6.93833C6.94824 7.46541 7.36991 7.88708 7.89699 7.88708C8.41449 7.88708 8.84574 7.46541 8.84574 6.93833C8.84574 6.41125 8.41449 5.98958 7.89699 5.98958Z'
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
};

export const ClockIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.35 15.57C16.21 15.81 15.96 15.94 15.7 15.94C15.57 15.94 15.44 15.91 15.32 15.83L12.22 13.98C11.45 13.52 10.88 12.51 10.88 11.62V7.52C10.88 7.11 11.22 6.77 11.63 6.77C12.04 6.77 12.38 7.11 12.38 7.52V11.62C12.38 11.98 12.68 12.51 12.99 12.69L16.09 14.54C16.45 14.75 16.57 15.21 16.35 15.57Z'
        fill='#9447F6'
      />
    </svg>
  );
};

export const JournalIcon = () => {
  return (
    <svg
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Group 1000003694'>
        <rect
          id='Rectangle 5063'
          opacity='0.08'
          width='50'
          height='50'
          rx='3'
          fill='#3758F9'
        />
        <g id='Group 1000002948'>
          <path
            id='Vector'
            d='M32.0084 14.0114H18.6381C18.5496 14.0041 18.4609 14.0003 18.3721 14C16.5098 14 15 15.5057 15 17.363C15 17.4534 15.0037 17.5429 15.0107 17.6314H15V35.2884C15 36.1784 15.7235 36.9 16.6159 36.9H18.47V33.2057C18.6315 33.255 18.7994 33.28 18.9682 33.2799H32.5505C33.4879 33.2799 34.2479 32.522 34.2479 31.587V16.2449C34.2479 15.0114 33.2452 14.0114 32.0084 14.0114Z'
            fill='#3758F9'
          />
          <path
            id='Vector_2'
            d='M33.2844 16.9195C33.2844 15.9187 32.3376 15.1005 31.1417 15.0384V15.0342H18.3706C17.4394 15.0342 16.6846 15.6107 16.6846 16.3218C16.6846 17.0329 17.4394 17.6094 18.3706 17.6094V17.6314H27.6244V35.4238L30.9957 35.8185C32.3049 35.8185 33.2844 33.2347 33.2844 31.9588V17.0092H33.2817C33.2834 16.9795 33.2844 16.9496 33.2844 16.9195Z'
            fill='#4EB9FF'
          />
          <path
            id='Vector_3'
            d='M30.3046 17.631H18.4707V36.8995H30.3046C31.2287 36.8995 31.9778 36.1524 31.9778 35.2307V19.2997C31.9778 18.3781 31.2287 17.631 30.3046 17.631Z'
            fill='#3758F9'
          />
          <path
            id='Vector_4'
            d='M27.6989 24.7665H21.6327C21.2698 24.7665 20.9756 24.4731 20.9756 24.1112V21.8306C20.9756 21.4687 21.2698 21.1753 21.6327 21.1753H27.6989C28.0618 21.1753 28.356 21.4687 28.356 21.8306V24.1112C28.356 24.4731 28.0618 24.7665 27.6989 24.7665Z'
            fill='#9AD7FF'
          />
        </g>
      </g>
    </svg>
  );
};

export const BookIcon = () => {
  return (
    <svg
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Icon' clip-path='url(#clip0_2_15996)'>
        <g id='Group 1000002941'>
          <path
            id='Vector'
            d='M16.3539 12.907C16.3539 12.907 5.21492 10.5682 5.10938 10.5461V25.5314L16.3539 27.3583L17.4197 20.1327L16.3539 12.907Z'
            fill='#F2994A'
          />
          <path
            id='Vector_2'
            d='M16.3535 12.907V27.3583L27.598 25.5314V10.5461L16.3535 12.907Z'
            fill='#174461'
          />
          <path
            id='Vector_3'
            d='M16.3539 10.7836L5.10938 8.95412V23.9395L16.3539 25.7664L17.4197 18.5407L16.3539 10.7836Z'
            fill='#F2994A'
          />
          <path
            id='Vector_4'
            d='M16.3535 10.7836V25.7664L27.598 23.9395V8.95412L16.3535 10.7836Z'
            fill='#F2994A'
          />
          <path
            id='Vector_5'
            d='M16.354 9.19018L17.4198 16.3653L16.354 23.5404L7.24121 21.627V7.27681L16.354 9.19018Z'
            fill='#E5D5C8'
          />
          <g id='Group'>
            <path
              id='Vector_6'
              d='M14.2229 12.8598C14.1863 12.8598 14.1493 12.8561 14.112 12.8482L9.26253 11.8248C8.97461 11.764 8.79057 11.4819 8.85149 11.1948C8.91246 10.9075 9.19537 10.7244 9.48321 10.7849L14.3327 11.8083C14.6207 11.8691 14.8047 12.1511 14.7438 12.4383C14.6907 12.6883 14.4694 12.8598 14.2229 12.8598Z'
              fill='#174461'
            />
            <path
              id='Vector_7'
              d='M14.2229 16.4451C14.1863 16.4451 14.1493 16.4413 14.112 16.4335L9.26253 15.4101C8.97461 15.3492 8.79057 15.0672 8.85149 14.78C8.91242 14.4928 9.19533 14.3095 9.48321 14.3701L14.3327 15.3935C14.6207 15.4543 14.8047 15.7364 14.7438 16.0236C14.6907 16.2736 14.4694 16.4451 14.2229 16.4451Z'
              fill='#174461'
            />
            <path
              id='Vector_8'
              d='M14.2229 20.0294C14.1863 20.0294 14.1493 20.0256 14.112 20.0178L9.26253 18.9944C8.97461 18.9336 8.79057 18.6515 8.85149 18.3643C8.91246 18.0771 9.19533 17.8939 9.48321 17.9544L14.3327 18.9778C14.6207 19.0386 14.8047 19.3207 14.7438 19.6079C14.6907 19.8579 14.4694 20.0294 14.2229 20.0294Z'
              fill='#174461'
            />
          </g>
          <path
            id='Vector_9'
            d='M16.3535 9.19018V23.5404L25.4663 21.627V7.27681L16.3535 9.19018Z'
            fill='#E5D5C8'
          />
          <g id='Group_2'>
            <path
              id='Vector_10'
              d='M18.4836 12.86C18.2372 12.86 18.0159 12.6885 17.9628 12.4384C17.9019 12.1513 18.086 11.8692 18.3739 11.8084L23.2234 10.785C23.5116 10.7242 23.7942 10.9077 23.8551 11.1949C23.9161 11.482 23.732 11.7641 23.4441 11.8249L18.5945 12.8484C18.5573 12.8562 18.5202 12.86 18.4836 12.86Z'
              fill='#174461'
            />
            <path
              id='Vector_11'
              d='M18.4836 16.4452C18.2372 16.4452 18.0159 16.2737 17.9628 16.0236C17.9019 15.7364 18.086 15.4544 18.3739 15.3936L23.2234 14.3701C23.5116 14.3094 23.7942 14.4929 23.8551 14.7801C23.9161 15.0672 23.732 15.3493 23.4441 15.4101L18.5945 16.4335C18.5573 16.4414 18.5202 16.4452 18.4836 16.4452Z'
              fill='#174461'
            />
            <path
              id='Vector_12'
              d='M18.4836 20.0295C18.2372 20.0295 18.0159 19.858 17.9628 19.6079C17.9019 19.3207 18.086 19.0387 18.3739 18.9779L23.2234 17.9544C23.5116 17.8938 23.7942 18.0773 23.8551 18.3644C23.9161 18.6515 23.732 18.9336 23.4441 18.9944L18.5945 20.0178C18.5573 20.0257 18.5202 20.0295 18.4836 20.0295Z'
              fill='#174461'
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_2_15996'>
          <rect width='32.56' height='32.56' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const VideoIcon = () => {
  return (
    <svg
      width='19'
      height='23'
      viewBox='0 0 19 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Group 1000004627'>
        <g id='Group'>
          <path
            id='Vector'
            d='M16.3167 0H2.28182C1.02143 0 0 1.0187 0 2.27536V20.7386C0 21.3244 0.240974 21.8848 0.666017 22.2887C1.09173 22.6929 1.66401 22.9059 2.25103 22.8775L15.0757 22.259V22.2587C15.553 22.2546 16.0108 22.1565 16.4278 21.9806C17.0664 21.7112 17.6072 21.2627 17.9908 20.6972C18.3736 20.1322 18.5985 19.4466 18.5985 18.7146V2.27536C18.5985 1.0187 17.5764 0 16.3167 0ZM17.2725 18.7146C17.2725 19.0237 17.2103 19.3141 17.0978 19.5798C16.9292 19.9777 16.6454 20.3188 16.2893 20.5584C15.9332 20.7984 15.5075 20.9373 15.0436 20.9376C14.7518 20.9372 14.4632 20.8678 14.1855 20.7277C13.7711 20.5174 13.3802 20.1442 13.0877 19.5968C12.7965 19.0501 12.6058 18.3295 12.6052 17.4436V17.0491C12.6052 16.9139 12.5376 16.788 12.4244 16.7136C12.3113 16.6395 12.1681 16.6269 12.0436 16.6806L2.07499 20.9643C1.90897 21.0354 1.71823 21.019 1.56759 20.9199C1.41632 20.8208 1.32594 20.6525 1.32594 20.4726V2.39284C1.32594 1.8014 1.80654 1.32208 2.3989 1.32208H16.1989C16.7919 1.32208 17.2725 1.8014 17.2725 2.39284V18.7146H17.2725Z'
            fill='#9B51E0'
          />
          <path
            id='Vector_2'
            d='M8.95504 8.08814C8.95705 6.99065 8.06682 6.09882 6.96644 6.09681C5.86601 6.09449 4.97179 6.98301 4.96973 8.08045C4.96776 9.17793 5.85795 10.0701 6.95837 10.0718C8.0588 10.0738 8.95369 9.18593 8.95504 8.08814ZM6.27295 8.08309C6.27363 7.70324 6.58284 7.39651 6.96375 7.39686C7.34461 7.39753 7.65248 7.70592 7.65181 8.08613C7.65109 8.46566 7.34192 8.77271 6.96106 8.77208C6.58087 8.77101 6.27295 8.46262 6.27295 8.08309Z'
            fill='#9B51E0'
          />
          <path
            id='Vector_3'
            d='M11.2227 10.0718C12.3231 10.0738 13.2174 9.18593 13.2194 8.08814C13.2214 6.99065 12.3305 6.09882 11.2301 6.09681C10.1297 6.09449 9.23542 6.98301 9.2334 8.08045C9.23072 9.17793 10.1223 10.0701 11.2227 10.0718ZM11.2281 7.39686C11.6082 7.39753 11.9162 7.70592 11.9155 8.08613C11.9149 8.46566 11.6056 8.77271 11.2247 8.77208C10.8445 8.77105 10.536 8.46266 10.5367 8.08313C10.5373 7.70329 10.8472 7.39651 11.2281 7.39686Z'
            fill='#9B51E0'
          />
          <path
            id='Vector_4'
            d='M11.216 10.5801H6.48102C6.24072 10.5801 6.0459 10.7743 6.0459 11.0143V13.8982C6.0459 14.1382 6.24072 14.3324 6.48102 14.3324H11.216C11.457 14.3324 11.6518 14.1382 11.6518 13.8982V11.0143C11.6518 10.7743 11.457 10.5801 11.216 10.5801Z'
            fill='#9B51E0'
          />
          <path
            id='Vector_5'
            d='M14.4915 10.6501C14.3463 10.5624 14.1655 10.5567 14.0156 10.6351L12.2311 11.5647C12.0712 11.6475 11.9707 11.8127 11.9707 11.9932V12.9015C11.9707 13.0817 12.0711 13.2466 12.2311 13.33L14.0156 14.2593C14.1655 14.3377 14.3463 14.332 14.4915 14.2445C14.6367 14.1564 14.7251 13.9996 14.7251 13.8303V11.0637C14.7251 10.8948 14.6367 10.738 14.4915 10.6501Z'
            fill='#9B51E0'
          />
        </g>
      </g>
    </svg>
  );
};

export const OtherResourceIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
    >
      <path
        d='M20.0678 21.8966C19.0225 21.8966 18.1748 21.0512 18.1748 20.0086V12.0794H21.2036C21.6219 12.0794 21.9608 12.4173 21.9608 12.8345V20.0086C21.9608 21.0512 21.1131 21.8966 20.0678 21.8966Z'
        fill='#DAD6E0'
      />
      <path
        d='M19.6869 17.3602H18.1726C17.9632 17.3602 17.7939 17.1914 17.7939 16.9826C17.7939 16.7738 17.9632 16.605 18.1726 16.605H19.6869C19.8963 16.605 20.0656 16.7738 20.0656 16.9826C20.0656 17.1914 19.8963 17.3602 19.6869 17.3602Z'
        fill='white'
      />
      <path
        d='M20.4442 15.8585H18.1726C17.9632 15.8585 17.7939 15.6897 17.7939 15.4809C17.7939 15.272 17.9632 15.1033 18.1726 15.1033H20.4442C20.6536 15.1033 20.8228 15.272 20.8228 15.4809C20.8228 15.6897 20.6535 15.8585 20.4442 15.8585Z'
        fill='white'
      />
      <path
        d='M19.6869 20.3833H18.1726C17.9632 20.3833 17.7939 20.2145 17.7939 20.0057C17.7939 19.7969 17.9632 19.6281 18.1726 19.6281H19.6869C19.8963 19.6281 20.0656 19.7969 20.0656 20.0057C20.0656 20.2145 19.8963 20.3833 19.6869 20.3833Z'
        fill='white'
      />
      <path
        d='M20.4442 18.8788H18.1726C17.9632 18.8788 17.7939 18.71 17.7939 18.5011C17.7939 18.2923 17.9632 18.1235 18.1726 18.1235H20.4442C20.6536 18.1235 20.8228 18.2923 20.8228 18.5011C20.8228 18.71 20.6535 18.8788 20.4442 18.8788Z'
        fill='white'
      />
      <path
        d='M18.1727 20.0121V18.1241V9.06207V0.755165C18.1727 0.337953 17.8339 0 17.4156 0H0.757191C0.338859 0 0 0.337953 0 0.755165V20.0121C0 21.0546 0.847685 21.9 1.893 21.9H20.0657C19.0204 21.9 18.1727 21.0546 18.1727 20.0121Z'
        fill='#828282'
      />
      <path
        d='M1.89062 6.04156H16.2774V3.77606H1.89062V6.04156Z'
        fill='white'
      />
      <path
        d='M16.28 2.26139H1.89326C1.68427 2.26139 1.51465 2.0926 1.51465 1.88378C1.51465 1.67496 1.68427 1.50618 1.89326 1.50618H16.28C16.489 1.50618 16.6586 1.67496 16.6586 1.88378C16.6586 2.0926 16.489 2.26139 16.28 2.26139Z'
        fill='#DAD6E0'
      />
      <path
        d='M14.0088 18.5047H16.2804V16.2392H14.0088V18.5047Z'
        fill='white'
      />
      <path
        d='M12.4901 17.3654H1.88936C1.68036 17.3654 1.51074 17.1966 1.51074 16.9878C1.51074 16.779 1.68036 16.6102 1.88936 16.6102H12.4901C12.6991 16.6102 12.8687 16.779 12.8687 16.9878C12.8687 17.1966 12.6991 17.3654 12.4901 17.3654Z'
        fill='#DAD6E0'
      />
      <path
        d='M12.4901 18.8794H1.88936C1.68036 18.8794 1.51074 18.7106 1.51074 18.5018C1.51074 18.2929 1.68036 18.1242 1.88936 18.1242H12.4901C12.6991 18.1242 12.8687 18.2929 12.8687 18.5018C12.8687 18.7106 12.6991 18.8794 12.4901 18.8794Z'
        fill='#DAD6E0'
      />
      <path
        d='M16.2761 20.3946H1.88936C1.68036 20.3946 1.51074 20.2258 1.51074 20.017C1.51074 19.8082 1.68036 19.6394 1.88936 19.6394H16.2761C16.4851 19.6394 16.6547 19.8082 16.6547 20.017C16.6547 20.2258 16.4851 20.3946 16.2761 20.3946Z'
        fill='#DAD6E0'
      />
      <path
        d='M9.07915 7.5554L4.92285 11.7088L6.05866 12.8416L9.07915 9.8209L9.08747 9.82919L9.0954 9.8209L12.1162 12.8416L13.252 11.7088L9.07915 7.5554Z'
        fill='white'
      />
      <path
        d='M9.08386 9.82104L6.81641 12.0866V15.1073H11.3596V12.0866L9.08386 9.82104Z'
        fill='#D9D9D9'
      />
    </svg>
  );
};

export const DeactivateUserIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
    >
      <path
        d='M16.8889 23.465C16.6831 23.465 16.4772 23.3892 16.3147 23.2267C16.0006 22.9125 16.0006 22.3925 16.3147 22.0783L19.3697 19.0233C19.6839 18.7092 20.2039 18.7092 20.5181 19.0233C20.8322 19.3375 20.8322 19.8575 20.5181 20.1717L17.4631 23.2267C17.3006 23.3892 17.0947 23.465 16.8889 23.465Z'
        fill='#828282'
      />
      <path
        d='M19.9439 23.465C19.7381 23.465 19.5322 23.3892 19.3697 23.2267L16.3147 20.1717C16.0006 19.8575 16.0006 19.3375 16.3147 19.0233C16.6289 18.7092 17.1489 18.7092 17.4631 19.0233L20.5181 22.0783C20.8322 22.3925 20.8322 22.9125 20.5181 23.2267C20.3556 23.3892 20.1497 23.465 19.9439 23.465Z'
        fill='#828282'
      />
      <path
        d='M13.1728 12.5883C13.1403 12.5883 13.1186 12.5883 13.0861 12.5883C13.032 12.5775 12.9561 12.5775 12.8911 12.5883C9.74945 12.4908 7.37695 10.0208 7.37695 6.97666C7.37695 3.87833 9.90112 1.35416 12.9995 1.35416C16.0978 1.35416 18.622 3.87833 18.622 6.97666C18.6111 10.0208 16.2278 12.4908 13.2053 12.5883C13.1945 12.5883 13.1836 12.5883 13.1728 12.5883ZM12.9995 2.97916C10.8003 2.97916 9.00195 4.7775 9.00195 6.97666C9.00195 9.14333 10.692 10.8875 12.8478 10.9633C12.9128 10.9525 13.0536 10.9525 13.1945 10.9633C15.3178 10.8658 16.9861 9.12166 16.997 6.97666C16.997 4.7775 15.1986 2.97916 12.9995 2.97916Z'
        fill='#828282'
      />
      <path
        d='M12.9998 24.44C10.7898 24.44 8.69892 23.8658 7.11725 22.8042C5.61142 21.7967 4.78809 20.4208 4.78809 18.9367C4.78809 17.4525 5.62225 16.0875 7.11725 15.0908C10.3564 12.9242 15.6214 12.9242 18.8606 15.0908C19.2289 15.34 19.3373 15.8492 19.0881 16.2175C18.8389 16.5967 18.3298 16.6942 17.9614 16.445C15.2639 14.6467 10.7139 14.6467 8.01642 16.445C6.97642 17.1383 6.41309 18.0158 6.41309 18.9367C6.41309 19.8575 6.98725 20.7567 8.01642 21.45C9.32725 22.3275 11.0931 22.8042 12.9889 22.8042C13.4331 22.8042 13.8014 23.1725 13.8014 23.6167C13.8014 24.0608 13.4439 24.44 12.9998 24.44Z'
        fill='#828282'
      />
    </svg>
  );
};
